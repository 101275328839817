'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC } from 'react'
import { type WebPage } from 'schema-dts'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokPageType } from '../../model/storyblokTypes.generated'
import { useStoryblokServerSideData } from '../../storyblok-server-side-data/context/storyblokServerSideDataContext'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

const getStructuredDataProps = (
  pageType?: WebPage['@type'] | null,
):
  | { itemScope: true; itemType: `https://schema.org/${WebPage['@type']}` }
  | undefined =>
  pageType
    ? {
        itemScope: true,
        itemType: `https://schema.org/${pageType}`,
      }
    : undefined

export const StoryblokPage: FC<StoryblokComponentProps<StoryblokPageType>> = ({
  blok,
}) => {
  const { body, variant } = blok

  const { data } = useStoryblokServerSideData(blok)

  return (
    <main
      className={clsx(
        'grid grid-cols-1',
        variant === 'content' && 'gap-9 py-4 desktop:py-6',
      )}
      {...getStructuredDataProps(data?.pageType)}
      {...storyblokEditable(blok)}
    >
      <StoryblokComponentsRenderer bloks={body} />
    </main>
  )
}
